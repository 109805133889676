import React, { useState } from 'react';
import './ElanYarat.css';
import ElanController from '../../api/elan/ElanController'; // ElanController'ı import et
import Loader from '../../components/Loader'; // Loader componentini import et
import { useNavigate } from 'react-router-dom'; // useNavigate import et

const IMG_BB_API_KEYS = [
    '4b8d7989751c89d9561fd1e44100bf68', // 🔑 1-ci API açarı
    '2c5257b8de4e5bf05fc5a7b2419a418e', // 🔁 2-ci API açarı
    '62346dd09fb59412e2774c150742d3a4'  // 🛑 3-cü API açarı
];

const ElanYarat = () => {
    const [form, setForm] = useState({
        userId: localStorage.getItem('motoTaksiUserId'),  // `madshopUserId`'yi localStorage'dan al
        image: '',     // Varsayılan olarak boş
        price: '',
        title: '',
        description: '',
        category: '',
        imageFile: null, // Şu an resim yüklenmediği için null
    });

    const [preview, setPreview] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate(); // useNavigate hook'unu kullanıyoruz

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setForm({ ...form, imageFile: file });

        // Preview üçün
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setPreview(null);
        }
    };

    // Resmi ImgBB'ye yükle
    const uploadImageToImgBB = async (file) => {
        const apiUrl = 'https://api.imgbb.com/1/upload';
        for (let key of IMG_BB_API_KEYS) {
            try {
                const formData = new FormData();
                formData.append('key', key);
                formData.append('image', file);

                const response = await fetch(apiUrl, {
                    method: 'POST',
                    body: formData,
                });
                const data = await response.json();
                if (data.success) {
                    console.log(`✅ Şəkil uğurla yükləndi - API: ${key}`);
                    return data.data.url;  // URL döndürülür
                } else {
                    throw new Error('Resim yüklenemedi');
                }
            } catch (error) {
                console.warn(`⚠️ API açarı uğursuz oldu: ${key}`);
            }
        }

        // Əgər heç bir API işləmirsə
        throw new Error("❌ Şəkil yüklənərkən bütün API açarları uğursuz oldu.");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            let imageUrl = null;
            // Resim varsa ImgBB'ye yükle
            if (form.imageFile) {
                imageUrl = await uploadImageToImgBB(form.imageFile);
            }

            // JSON verisi ile API'ye veri gönderme
            const requestData = {
                title: form.title,
                price: form.price,
                description: form.description,
                category: form.category,
                image: imageUrl,  // ImgBB'den gelen URL
                userId: form.userId || null,  // Kullanıcı ID'si (localStorage'dan alınan değer)
                premium: false,  // Varsayılan premium değeri
                expireDate: null,  // Varsayılan expireDate
                isDeleted: false,  // Varsayılan isDeleted
                createdAt: new Date().toISOString(), // Şu anki tarih
                updatedAt: new Date().toISOString(), // Şu anki tarih
            };

            // ElanController'ı kullanarak veriyi backend'e göndermek
            const response = await ElanController.createElan(requestData);
            console.log('Elan yaradıldı:', response);

            // Elan başarılı bir şekilde oluşturulursa, kullanıcıyı profile sayfasına yönlendir
            navigate('/profile/elanlarim');
        } catch (error) {
            console.error('Elan yaratılarkən xəta:', error);
        } finally {
            setIsLoading(false);  // Yükleme tamamlandığında loader'ı gizle
        }
    };

    return (
        <div className="elan-container">
            <h2>Yeni Elan Yarat</h2>
            <form className="elan-form" onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="title"
                    placeholder="Başlıq"
                    value={form.title}
                    onChange={handleChange}
                    required
                />

                <input
                    type="number"
                    name="price"
                    placeholder="Qiymət (₼)"
                    value={form.price}
                    onChange={handleChange}
                    max="999999"  // Maksimum 6 haneli sayı
                    required
                />


                <textarea
                    name="description"
                    placeholder="Açıqlama"
                    value={form.description}
                    onChange={handleChange}
                    required
                ></textarea>

                <select
                    name="category"
                    value={form.category}
                    onChange={handleChange}
                    required
                >
                    <option value="">Kateqoriya seçin</option>
                    <option value="taxi">Moto Taksi</option>
                    <option value="sales">Moto Alqı-Satqı</option>
                    <option value="rent">Moto Kirayə</option>
                    <option value="spare">Moto Zapçast</option>
                </select>

                <label htmlFor="image-upload" className="file-label">
                    Şəkil seçin
                </label>
                <input
                    type="file"
                    id="image-upload"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleImageChange}
                    required  // Resim alanının zorunlu olduğunu belirtiyoruz
                />

                {preview && (
                    <div className="image-preview">
                        <img src={preview} alt="Preview" />
                    </div>
                )}

                <button type="submit" disabled={isLoading}>
                    {isLoading ? 'Yüklənir...' : 'Elan Yarat'}
                </button>
            </form>

            {/* Loader componentini burada ekleyin */}
            {isLoading && <Loader />}
        </div>
    );
};

export default ElanYarat;
