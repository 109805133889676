import React, { useEffect, useState } from 'react';
import { getAllChats } from '../../../api/sifarish/SifarishController';
import './MotoTaksiSifarishAdmin.css';
import { useNavigate } from 'react-router-dom';
import { updateChat, deleteChat } from '../../../api/sifarish/SifarishController';

const MotoTaksiSifarishAdmin = () => {
    const [chats, setChats] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedChat, setSelectedChat] = useState(null);
    const [newMessage, setNewMessage] = useState('');

    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1); // əvvəlki səhifəyə geri dön
    };

    const handleDeleteChat = async (chatId) => {
        const confirmDelete = window.confirm("Bu sifarişi silmək istədiyinizə əminsiniz?");
        if (confirmDelete) {
            try {
                await deleteChat(chatId);
                setChats(prev => prev.filter(chat => chat.id !== chatId));
                alert("Sifariş silindi.");
            } catch (error) {
                console.error("Silmə zamanı xəta:", error);
                alert("Sifariş silinə bilmədi.");
            }
        }
    };

    const handleEditChat = (chat) => {
        setSelectedChat(chat);
        setNewMessage(chat.message);
        setIsModalOpen(true);
    };

    const handleSaveMessage = async () => {
        if (!selectedChat || newMessage.trim() === '') return;

        try {
            const updated = { ...selectedChat, message: newMessage };
            await updateChat(selectedChat.id, updated);
            setChats(prev =>
                prev.map(c => (c.id === selectedChat.id ? { ...c, message: newMessage } : c))
            );
            setIsModalOpen(false);
            alert("Mesaj yeniləndi.");
        } catch (error) {
            console.error("Yeniləmə zamanı xəta:", error);
            alert("Mesaj yenilənə bilmədi.");
        }
    };

    const handleUsernameClick = (userId) => {
        navigate(`/private/istifadeciler/${userId}`);
    };

    // Yeni funksiya: Sifarişi tamamla
    const handleCompleteOrder = async (chat) => {
        const updatedChat = { ...chat, isCompleted: true };
        try {
            await updateChat(chat.id, updatedChat);
            setChats(prev =>
                prev.map(c => (c.id === chat.id ? { ...c, isCompleted: true } : c))
            );
            alert("Sifariş tamamlandı!");
        } catch (error) {
            console.error("Sifariş tamamlanarkən xəta:", error);
            alert("Sifariş tamamlanmadı.");
        }
    };

    useEffect(() => {
        const fetchChats = async () => {
            try {
                const data = await getAllChats();
                const websiteChats = data
                .filter(chat => chat.isWebsite === true)
                .reverse();
                setChats(websiteChats);
            } catch (error) {
                console.error('Söhbətlər alınarkən xəta baş verdi:', error);
            }
        };

        fetchChats();
    }, []);


    return (
        <div className="moto-taksi-sifarish-container">
            <button className="back-button" onClick={handleBack}>Geri</button>
            <h1 className="moto-taksi-sifarish-title">Moto Taksi Sifarişləri (Admin)</h1>

            <div className="moto-taksi-sifarish-grid">
                {chats.length === 0 ? (
                    <div className="no-orders-message">Websayt içərisindən moto taksi sifarişi yoxdur.</div>
                ) : (
                    chats.map((chat) => (
                        <div key={chat.id} className="moto-taksi-sifarish-card">
                            <div className="moto-taksi-sifarish-card-header">
                                <span className="moto-taksi-sifarish-card-id">
                                    <span>ID:</span> {chat.id}
                                </span>
                                {chat.isCompleted ? (
                                    <span className="moto-taksi-sifarish-status completed">Tamamlanıb</span>
                                ) : (
                                    <span className="moto-taksi-sifarish-status pending">Gözləyir</span>
                                )}
                            </div>
                            <p className="moto-taksi-sifarish-text-block">
                                <strong>Müştəri:</strong>{' '}
                                <span
                                    className="clickable-username"
                                    onClick={() => handleUsernameClick(chat.userId)}
                                >
                                    {chat.username}
                                </span>
                            </p>
                            <p className="moto-taksi-sifarish-text-block">
                                <strong>Mesaj:</strong> {chat.message}
                            </p>
                            <p className="moto-taksi-sifarish-timestamp">
                                {new Date(chat.timestamp).toLocaleString('az-AZ', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                })}
                            </p>
                            <div className="moto-taksi-sifarish-actions">
                                <button onClick={() => handleEditChat(chat)} className="edit-btn">Düzəlt</button>
                                <button onClick={() => handleDeleteChat(chat.id)} className="delete-btn">Sil</button>

                                {/* Yeni düymə əlavə edirik: Sifarişi tamamla */}
                                {!chat.isCompleted && (
                                    <button onClick={() => handleCompleteOrder(chat)} className="complete-btn">
                                        Sifarişi Tamamla
                                    </button>
                                )}
                            </div>
                        </div>
                    ))
                )}
            </div>

            {isModalOpen && (
                <div className="moto-taksi-sifarish-modal-overlay">
                    <div className="moto-taksi-sifarish-modal">
                        <h3>Mesajı redaktə et</h3>
                        <textarea
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            className="moto-taksi-sifarish-textarea"
                        ></textarea>
                        <div className="moto-taksi-sifarish-modal-actions">
                            <button onClick={handleSaveMessage} className="save-btn">Yadda saxla</button>
                            <button onClick={() => setIsModalOpen(false)} className="cancel-btn">Ləğv et</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MotoTaksiSifarishAdmin;
