import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './ElanAdmin.css';
import moment from 'moment';
import 'moment/locale/az';
import ElanController from '../../../api/elan/ElanController';
import UserController from '../../../api/user/UserController';

const ElanAdmin = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [elan, setElan] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [contact, setContact] = useState(null);


  useEffect(() => {
    const loadElan = async () => {
      try {
        const response = await ElanController.getElanById(id);
        setElan(response);

        if (response.userId) {
          const userResponse = await UserController.getUserById(response.userId);
          setContact(userResponse);
        }
      } catch (error) {
        console.error('Elan yüklənərkən xəta:', error);
      } finally {
        setIsLoading(false);
      }
    };
    loadElan();
  }, [id]);

  const handleBoostAd = () => {
    alert('Elanı irəli çəkmə funksiyası hələlik aktiv deyil.');
  };


  const handleBack = () => {
    navigate(-1);
  };

  const handleUsernameClick = (userId) => {
    navigate(`/private/istifadeciler/${userId}`);
  };


  const handleDelete = async () => {
    const confirmed = window.confirm("Bu elanı silmək istədiyinizə əminsiniz?");
    if (confirmed) {
      try {
        await ElanController.deleteElanById(id);
        alert("Elan silindi.");
        navigate("/profile"); // Yönləndirmə profilə və ya ana səhifəyə
      } catch (error) {
        console.error("Elan silinərkən xəta:", error);
        alert("Elanı silmək mümkün olmadı.");
      }
    }
  };

  const handleEdit = () => {
    navigate(`/private/edit-elan/${id}`);
  };

  if (isLoading) {
    return <div>Yüklənir...</div>;
  }

  return (
    <div className="elan-container">
      <button className="back-button" onClick={handleBack}>Geri</button>

      <div className="elan-header">
        <img src={elan.image} alt={elan.title} className="elan-image" />
        <div className="elan-info">
          <h2 className="elan-title">{elan.title}</h2>
          <p className="elan-price">{elan.price} ₼</p>
          <div className="elan-meta">
            <span>{moment(elan.createdAt).locale('az').format('LL, HH:mm')}</span>
            {elan.premium && <span className="premium-badge">PREMIUM</span>}
          </div>

          <div className="elan-body">{elan.description}</div>

          <div className="elan-footer">
            <div className="contact-info">
              <div className="contact-person">
                <span><strong>Əlaqə:</strong></span>
                <span
                  className="clickable-username"
                  onClick={() => handleUsernameClick(contact.id)}
                >
                  {contact ? contact.name : 'Yüklənir...'}
                </span>
                <span>{contact ? contact.phoneNumber : 'Yüklənir...'}</span>
              </div>

            </div>
          </div>

          <div className="elan-actions">
            <button className="boost-btn" onClick={handleBoostAd}>Elanı irəli çək</button>

            {contact && contact.phoneNumber && (
              <>
                <a className="call-btn" href={`tel:${contact.phoneNumber}`}>Zəng et</a>
                <a
                  className="whatsapp-btn"
                  href={`https://wa.me/${contact.phoneNumber.replace(/\D/g, '')}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Whatsapp ilə əlaqə
                </a>
              </>
            )}

            {/* Əgər bu istifadəçinin öz elanıdırsa, düzəliş və sil düymələrini göstər */}
            <div className="owner-actions">
              <button className="edit-btn" onClick={handleEdit}>Düzəliş et</button>
              <button className="delete-btn" onClick={handleDelete}>Sil</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default ElanAdmin;
