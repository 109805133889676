import React, { useState, useEffect, useRef } from 'react';
import './Chat.css';
import { FaTimes, FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { connectWebSocket, disconnectWebSocket, sendMessage, getAllChats } from '../../api/chat/ChatController'; // WebSocket controller
import UserController from '../../api/user/UserController';
import moment from 'moment';
import 'moment/locale/az'; // Azərbaycan dili üçün moment locale

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [userData, setUserData] = useState(null);
  const chatBodyRef = useRef(null);
  const navigate = useNavigate();

  // Moment locale to Azerbaijani
  moment.locale('az');

  // Close chat window
  const handleClose = () => {
    navigate(-1);
  };

  // İlk girişdə mesajları API-dən gətir
  useEffect(() => {
    const fetchInitialChats = async () => {
      try {
        const data = await getAllChats();
        setMessages(data);
        setTimeout(() => scrollToBottom(), 300); // Gözləyib scroll et
      } catch (error) {
        console.error("Mesajlar yüklənərkən xəta:", error);
      }
    };

    fetchInitialChats();
  }, []);

  const fetchUserDetails = async () => {
    const userId = localStorage.getItem('motoTaksiUserId');

    if (!userId) return null;

    try {
      const user = await UserController.getUserById(userId);
      return {
        id: parseInt(userId),
        name: user.name || 'İstifadəçi',
      };
    } catch (error) {
      console.error('İstifadəçi məlumatları alınarkən xəta:', error);
      return {
        id: parseInt(userId),
        name: 'Bilinməyən',
      };
    }
  };

  useEffect(() => {
    scrollToBottom(); // Yeni mesaj gələndə sona scroll
  }, [messages]);

  // Fetch initial user data
  useEffect(() => {
    const fetchUserData = async () => {
      const userInfo = await fetchUserDetails();
      setUserData(userInfo);
    };

    fetchUserData();
  }, []);

  // WebSocket connection and message fetching
  useEffect(() => {
    connectWebSocket((chatMessage) => {
      setMessages(prevMessages => {
        const alreadyExists = prevMessages.some(msg => msg.id === chatMessage.id);
        return alreadyExists ? prevMessages : [...prevMessages, chatMessage];
      });
    });

    return () => {
      disconnectWebSocket();
    };
  }, []);

  // Send a message
  const handleSendMessage = () => {
    if (message.trim() && userData) {
      const chatData = {
        userId: userData.id,
        username: userData.name,
        message: message,
        timestamp: new Date().toISOString(),
        isReadIds: [],
      };

      sendMessage(chatData);
      setMessage('');
    }
  };

  // Scroll to the bottom of the chat
  const scrollToBottom = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom(); // Scroll down each time messages change
  }, [messages]);

  // Tarixi formatlama funksiyası
  const formatTimestamp = (timestamp) => {
    const messageTime = moment(timestamp);

    if (messageTime.isSame(moment(), 'day')) {
      return messageTime.format('HH:mm'); // Bugün saat formatı
    } else if (messageTime.isSame(moment().subtract(1, 'days'), 'day')) {
      return 'Dünən ' + messageTime.format('HH:mm'); // Dünən saat formatı
    } else {
      return messageTime.format('D MMMM YYYY, HH:mm'); // Real tarix
    }
  };

  const groupMessagesByDate = (messages) => {
    if (!messages || messages.length === 0) return [];

    const grouped = messages.reduce((groups, message) => {
      const messageDate = moment(message.timestamp).format('D MMMM YYYY');
      if (!groups[messageDate]) {
        groups[messageDate] = [];
      }
      groups[messageDate].push(message);
      return groups;
    }, {});

    return Object.entries(grouped).map(([date, messages]) => ({
      title: date,
      data: messages,
    }));
  };

  const isAdmin = (msg) => {
    return msg.userId === 1 && msg.username === 'Ələkbər (Admin)';
  };

  return (
    <div className="chat-overlay">
      <div className="chat-box">
        <div className="chat-header">
          <span className="chat-back" onClick={handleClose}><FaArrowLeft /></span>
          <div className="chat-title">Çat - MotoTaksi.az</div>
          <FaTimes className="chat-close" onClick={handleClose} />

        </div>

        <div className="whatsapp-join-btn-container">
          <a
            href="https://chat.whatsapp.com/Lg1Emr5KfHeCagALQDg3sZ"
            target="_blank"
            rel="noopener noreferrer"
            className="whatsapp-join-btn"
          >
            ✅ WhatsApp Qrupumuza Qoşul
          </a>
        </div>

        <div className="chat-body" ref={chatBodyRef}>
          {groupMessagesByDate(messages).map((group, index) => (
            <div key={index} className="chat-date-group">
              <div className="chat-date-title">{group.title}</div>
              {group.data.map((msg, msgIndex) => (
                <div key={msgIndex} className={`chat-message ${isAdmin(msg) ? 'admin-message' : ''}`}>
                  <strong>{msg.username}</strong>
                  <p>{msg.message}</p>
                  <small>{formatTimestamp(msg.timestamp)}</small>
                </div>
              ))}
            </div>
          ))}
        </div>

        <div className="chat-input">
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Mesaj yazın..."
          />
          <button className="send-btn" onClick={handleSendMessage}>Göndər</button>
        </div>
      </div>
    </div>
  );
};

export default Chat;
