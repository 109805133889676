import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Yönləndirmə üçün useNavigate
import './ElanlarAdmin.css'; // Stil faylını import edirik
import ElanController from '../../../api/elan/ElanController';
import UserController from '../../../api/user/UserController'; // UserController import edirik
import Loader from '../../../components/Loader';
import ElanCard from '../../../components/ElanCard';

const ElanlarAdmin = () => {
  const [selectedCategory, setSelectedCategory] = useState('taxi');
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredElans, setFilteredElans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [usernames, setUsernames] = useState({}); // to store the usernames of users

  const categories = [
    { name: 'Moto Taksi Elanları', value: 'taxi' },
    { name: 'Moto Alqı-Satqı Elanları', value: 'sales' },
    { name: 'Moto Kirayə Elanları', value: 'rent' },
    { name: 'Moto Zapçast Elanları', value: 'spare' },
  ];

  const navigate = useNavigate(); // useNavigate hookunu istifadə edirik

  useEffect(() => {
    const loadElans = async () => {
      try {
        const response = await ElanController.getAllElans();

        if (response && Array.isArray(response)) {
          const categoryElans = response.filter(elan => elan.category === selectedCategory);

          // Axtarışa görə filtrlemə
          const filteredData = categoryElans.filter(elan =>
            elan.title.toLowerCase().includes(searchQuery.toLowerCase())
          );

          setFilteredElans(filteredData.reverse());

          // İstifadəçiləri yükləyirik
          const usersData = {};
          for (const elan of filteredData) {
            const user = await UserController.getUserById(elan.userId);  // User məlumatlarını çəkirik
            usersData[elan.userId] = user.name;  // Username-ni map-layırıq
          }

          setUsernames(usersData); // Username məlumatlarını saxlayırıq
        } else {
          throw new Error('Serverdən gələn məlumat düzgün formatda deyil');
        }
      } catch (error) {
        console.error('Elanlar yüklənərkən xəta:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadElans();
  }, [selectedCategory, searchQuery]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category); // Kategoriya seçimi dəyişir
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value); // Axtarış sorğusu dəyişir
  };

  const handleUsernameClick = (userId) => {
    navigate(`/private/istifadeciler/${userId}`);
  };
  
  const handleElanClick = (elanId) => {
  navigate(`/private/elan/${elanId}`);
};


  const handleBack = () => {
    navigate(-1); // Geri getmək üçün bu funksiyanı istifadə edirik
  };

  return (
    <div className="elan-container">
      <button onClick={handleBack} className="back-button">
        Geri
      </button>

      <div className="category-buttons">
        {categories.map((category) => (
          <button
            key={category.value}
            className={`category-button ${selectedCategory === category.value ? 'active' : ''}`}
            onClick={() => handleCategoryClick(category.value)}
          >
            {category.name}
          </button>
        ))}
      </div>

      <div className="search-section">
        <input
          type="text"
          placeholder="Axtarış"
          className="search-input"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>

      <div className="elans-container">
        {isLoading ? (
          <Loader />
        ) : (
          filteredElans.length === 0 ? (
            <div className="no-elans">Heç bir elan tapılmadı.</div>
          ) : (
            filteredElans.map((elan, index) => (
              <ElanCard
                key={index}
                title={elan.title}
                price={elan.price}
                image={elan.image}
                description={elan.description}
                createdAt={elan.createdAt}
                premium={elan.premium}
                username={usernames[elan.userId]}  // Burada user.name-i göndəririk
                userId={elan.userId} // ElanCard-a userId göndəririk
                onUsernameClick={handleUsernameClick}  // Username kliklənərkən istifadə ediləcək funksiya
                onClick={() => handleElanClick(elan.id)}
              />
            ))
          )
        )}
      </div>
    </div>
  );
};

export default ElanlarAdmin;
