import React, { useState } from 'react';
import '../auth/Auth.css';
import Loader from '../../components/Loader';
import UserController from '../../api/user/UserController'; // API çağırışı üçün import
import { useNavigate } from 'react-router-dom'; // Yönləndirmək üçün import
import moment from 'moment'; // moment.js əlavə et
import 'moment/locale/az'; // Azərbaycan dilində lokalizasiyanı əlavə et

const Auth = () => {
  const [isLogin, setIsLogin] = useState(true); // İstifadəçi giriş və ya qeydiyyat etməli olduğunu müəyyən edir
  const [isLoading, setIsLoading] = useState(false); // Yükleniyor vəziyyəti
  const [form, setForm] = useState({
    name: '', // Ad
    phoneNumber: '', // Telefon nömrəsi
    email: '', // E-poçt
    password: '' // Şifrə
  });

  const navigate = useNavigate(); // Yönləndirmək üçün hook

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const getFormattedDate = () => {
    return moment().locale('az').format('DD MMMM YYYY HH:mm'); // Tarixi formatlayır
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (isLogin) {
        const loginRequest = {
          phoneNumber: form.phoneNumber,
          password: form.password
        };

        const loginResponse = await UserController.loginUser(loginRequest);

        if (loginResponse && loginResponse.id) {
          if (loginResponse.isDisable) {
            alert('Hesabınız Admin tərəfindən bloklanıb.');
            setIsLoading(false);
            return;
          }

          // İstifadəçi məlumatlarını yadda saxla
          localStorage.setItem('motoTaksiUserId', loginResponse.id);
          localStorage.setItem('currentPassword', form.password); // Şifrəni saxla

          navigate('/');
        } else {
          alert("Telefon nömrəsi və ya şifrə yanlışdır.");
        }
      } else {
        // Qeydiyyat
        const existingUsers = await UserController.getAllUsers();

        const phoneExists = existingUsers.some(
          (user) => user.phoneNumber === form.phoneNumber
        );
        if (phoneExists) {
          alert("Bu telefon nömrəsi ilə artıq qeydiyyat mövcuddur.");
          setIsLoading(false);
          return;
        }

        const emailExists = existingUsers.some(
          (user) => user.email === form.email
        );
        if (emailExists) {
          alert("Bu e-poçt ünvanı ilə artıq qeydiyyat mövcuddur.");
          setIsLoading(false);
          return;
        }

        const registerData = {
          name: form.name,
          phoneNumber: form.phoneNumber,
          email: form.email,
          password: form.password,
          createdDate: getFormattedDate()
        };

        const registerResponse = await UserController.createUser(registerData);

        if (registerResponse && registerResponse.id) {
          localStorage.setItem('motoTaksiUserId', registerResponse.id);
          localStorage.setItem('currentPassword', form.password); // Şifrəni saxla
          navigate('/');
        } else {
          alert("Qeydiyyat zamanı xəta baş verdi.");
        }
      }
    } catch (error) {
      console.error('Xəta baş verdi:', error);
      alert('Xəta baş verdi, zəhmət olmasa daha sonra yenidən cəhd edin.');
    } finally {
      // Loader-i minimum 2 saniyə göstər
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };


  return (
    <div className="auth-container">
      <div className="auth-box">
        <div className="auth-right">
          <div className="auth-tabs">
            <button
              className={isLogin ? 'active' : ''}
              onClick={() => setIsLogin(true)}>
              Daxil Ol
            </button>
            <button
              className={!isLogin ? 'active' : ''}
              onClick={() => setIsLogin(false)}>
              Qeydiyyat
            </button>
          </div>

          <p className="auth-desc">
            {isLogin ? 'Sayta daxil olmaq üçün aşağıdakı məlumatları daxil edin.' : 'Yeni hesab yaratmaq üçün məlumatları daxil edin.'}
          </p>

          <form onSubmit={handleSubmit}>
            {/* Qeydiyyat formunda adı, telefon nömrəsini və e-poçtu göstəririk */}
            {!isLogin && (
              <>
                <input
                  type="text"
                  name="name"
                  placeholder="Ad"
                  value={form.name}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  name="phoneNumber"
                  placeholder="Telefon Nömrəsi"
                  value={form.phoneNumber}
                  onChange={handleChange}
                  required
                />
                <input
                  type="email"
                  name="email"
                  placeholder="E-poçt"
                  value={form.email}
                  onChange={handleChange}
                  required
                />
              </>
            )}

            {/* Giriş formunda telefon nömrəsini göstəririk */}
            {isLogin && (
              <input
                type="text"
                name="phoneNumber"
                placeholder="Telefon Nömrəsi"
                value={form.phoneNumber}
                onChange={handleChange}
                required
              />
            )}

            {/* Giriş və qeydiyyatda parol eyni olacaq */}
            <input
              type="password"
              name="password"
              placeholder="Parol"
              value={form.password}
              onChange={handleChange}
              required
            />

            <button className="auth-submit" type="submit">
              {isLogin ? 'Daxil Ol' : 'Qeydiyyat'}
            </button>

            {isLogin && (
              <button className="auth-reset">
                Şifrəni bərpa edin
              </button>
            )}

            {isLoading && <Loader />} {/* Loader burada göstəriləcək */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Auth;
