import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-container">
      <div className="about-box">
        <h2 className="about-title">Haqqımızda</h2>
        <p className="about-text">
          MotoTaksi.az Azərbaycanda ilk moto taksi və moto elan platformasıdır.
          Biz istifadəçilərə həm sürətli çatdırılma xidmətləri, həm də moto alqı-satqı,
          kirayə və zapçast elanlarını yerləşdirmə imkanı təqdim edirik.
        </p>

        <p className="about-text">
          Məqsədimiz həm kuryer, həm də müştəri təcrübəsini daha rahat, sürətli və effektiv etməkdir.
          Moto taksi xidmətləri ilə şəhər içi hərəkət daha çevik, moto bazarı ilə isə satış və alış daha asan olur.
        </p>

        <p className="about-text">
          Saytımız gündəlik inkişaf edir və biz daim istifadəçi məmnuniyyətini əsas prioritet hesab edirik.
          Əlavə təklif və iradlarınız üçün bizimlə əlaqə saxlamaqdan çəkinməyin.
        </p>
      </div>
    </div>
  );
};

export default About;
