import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminLogin.css'; // Stil faylını import edirik
import AdminController from '../../../api/admin/AdminController';
import Loader from '../../../components/Loader';

const AdminLogin = () => {
  const navigate = useNavigate();
  
  // Form məlumatları üçün state
  const [formData, setFormData] = useState({
    phoneNumber: '',  // phoneNumber istifadə edirik, çünki backend bunu gözləyir
    password: '',
  });
  
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  // Form məlumatlarını handle etmək üçün funksiya
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Giriş funksiyası
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      // AdminController loginAdmin funksiyası ilə API-ya sorğu göndəririk
      const response = await AdminController.loginAdmin(formData);
      
      if (response && response.id) {
        localStorage.setItem('motoTaksiAdminId', response.id); // Admin ID-ni localStorage-də saxlayırıq
        navigate('/private'); // Dashboard səhifəsinə yönləndiririk
      } else {
        setError('Giriş zamanı xəta baş verdi. Məlumatlarınızı yoxlayın.');
      }
    } catch (error) {
      setError('Giriş zamanı xəta baş verdi. Zəhmət olmasa yenidən cəhd edin.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="admin-login-container">
      <div className="admin-login-box">
        <h2 className="admin-login-title">Admin Giriş</h2>
        {error && <p className="admin-login-error-message">{error}</p>}
        
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="phoneNumber"  // phoneNumber ilə backend-dən uyğunlaşır
            className="admin-login-input"
            placeholder="Telefon Nömrəsi"
            value={formData.phoneNumber}  // phoneNumber istifadə edirik
            onChange={handleChange}
            required
          />
          <input
            type="password"
            name="password"
            className="admin-login-input"
            placeholder="Şifrə"
            value={formData.password}
            onChange={handleChange}
            required
          />
          
          {/* Loader göstəricisini yalnız yüklənmə zamanı göstəririk */}
          {isLoading ? (
            <Loader />  // Loader komponentini yüklənmə zamanı göstəririk
          ) : (
            <button type="submit" className="admin-login-button">
              Giriş
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
