import React, { useState, useEffect, useRef } from 'react';
import './ChatAdmin.css';
import { FaTimes, FaArrowLeft, FaTrashAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { connectWebSocket, disconnectWebSocket, sendMessage, getAllChats, deleteChat } from '../../../api/chat/ChatController';
import UserController from '../../../api/user/UserController';
import moment from 'moment';
import 'moment/locale/az';

const ChatAdmin = () => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [userData, setUserData] = useState(null);
  const chatBodyRef = useRef(null);
  const navigate = useNavigate();

  moment.locale('az');

  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchInitialChats = async () => {
      try {
        const data = await getAllChats();
        setMessages(data);
        setTimeout(() => scrollToBottom(), 300);
      } catch (error) {
        console.error("Mesajlar yüklənərkən xəta:", error);
      }
    };

    fetchInitialChats();
  }, []);

  useEffect(() => {
    setUserData({
      id: 1,
      name: 'Ələkbər (Admin)',
    });
  }, []);

  useEffect(() => {
    connectWebSocket((chatMessage) => {
      setMessages(prevMessages => {
        const alreadyExists = prevMessages.some(msg => msg.id === chatMessage.id);
        return alreadyExists ? prevMessages : [...prevMessages, chatMessage];
      });
    });

    return () => {
      disconnectWebSocket();
    };
  }, []);

  const handleSendMessage = () => {
    if (message.trim() && userData) {
      const chatData = {
        userId: userData.id,
        username: userData.name,
        message: message,
        timestamp: new Date().toISOString(),
        isReadIds: [],
      };

      sendMessage(chatData);
      setMessage('');
    }
  };

  const scrollToBottom = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const formatTimestamp = (timestamp) => {
    const messageTime = moment(timestamp);
    if (messageTime.isSame(moment(), 'day')) {
      return messageTime.format('HH:mm');
    } else if (messageTime.isSame(moment().subtract(1, 'days'), 'day')) {
      return 'Dünən ' + messageTime.format('HH:mm');
    } else {
      return messageTime.format('D MMMM YYYY, HH:mm');
    }
  };

  const groupMessagesByDate = (messages) => {
    if (!messages || messages.length === 0) return [];

    const grouped = messages.reduce((groups, message) => {
      const messageDate = moment(message.timestamp).format('D MMMM YYYY');
      if (!groups[messageDate]) {
        groups[messageDate] = [];
      }
      groups[messageDate].push(message);
      return groups;
    }, {});

    return Object.entries(grouped).map(([date, messages]) => ({
      title: date,
      data: messages,
    }));
  };

  const handleUserClick = async (userId) => {
    try {
      const user = await UserController.getUserById(userId);
      if (user && user.id) navigate(`/private/istifadeciler/${user.id}`);
    } catch (error) {
      console.error("İstifadəçiyə keçid zamanı xəta:", error);
    }
  };

  const handleDeleteMessage = async (chatId) => {
    try {
      await deleteChat(chatId);
      setMessages(prev => prev.filter(msg => msg.id !== chatId));
    } catch (error) {
      console.error("Mesaj silinərkən xəta:", error);
    }
  };

  return (
    <div className="chat-overlay">
      <div className="chat-box">
        <div className="chat-header">
          <span className="chat-back" onClick={handleClose}><FaArrowLeft /></span>
          <div className="chat-title">Admin Çat - MotoTaksi.az</div>
          <FaTimes className="chat-close" onClick={handleClose} />
        </div>

        <div className="chat-body" ref={chatBodyRef}>
          {groupMessagesByDate(messages).map((group, index) => (
            <div key={index} className="chat-date-group">
              <div className="chat-date-title">{group.title}</div>
              {group.data.map((msg, msgIndex) => (
                <div key={msgIndex} className={`chat-message ${msg.username === 'Ələkbər (Admin)' ? 'admin-message' : ''}`}>
                  <div className="chat-message-header">
                    <strong
                      className="clickable-username"
                      onClick={() => handleUserClick(msg.userId)}
                    >
                      {msg.username}
                    </strong>
                    <FaTrashAlt
                      className="delete-icon"
                      onClick={() => handleDeleteMessage(msg.id)}
                    />
                  </div>
                  <p>{msg.message}</p>
                  <small>{formatTimestamp(msg.timestamp)}</small>
                </div>
              ))}
            </div>
          ))}
        </div>

        <div className="chat-input">
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Mesaj yazın..."
          />
          <button className="send-btn" onClick={handleSendMessage}>Göndər</button>
        </div>
      </div>
    </div>
  );
};

export default ChatAdmin;