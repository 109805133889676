// src/api/AdminController.js

import axios from 'axios';

const BASE_URL = 'https://mototaksi.az:9999/api/v6/admin';
const LOGIN_URL = 'https://mototaksi.az:9999/api/v6/login/admin';

const AdminController = {
  // Get all admins
  getAllAdmins: async () => {
    try {
      const response = await axios.get(BASE_URL);
      return response.data;
    } catch (error) {
      console.error('Error fetching admins:', error);
      throw error;
    }
  },

  // Get admin by ID
  getAdminById: async (adminId) => {
    try {
      const response = await axios.get(`${BASE_URL}/${adminId}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching admin with ID ${adminId}:`, error);
      throw error;
    }
  },

  // Create new admin
  createAdmin: async (adminData) => {
    try {
      const response = await axios.post(BASE_URL, adminData);
      return response.data;
    } catch (error) {
      console.error('Error creating admin:', error);
      throw error;
    }
  },

  // Update admin
  updateAdmin: async (adminId, adminData) => {
    try {
      const response = await axios.put(`${BASE_URL}/${adminId}`, adminData);
      return response.data;
    } catch (error) {
      console.error(`Error updating admin with ID ${adminId}:`, error);
      throw error;
    }
  },

  // Admin login
  loginAdmin: async (loginData) => {
    try {
      const response = await axios.post(LOGIN_URL, loginData);
      return response.data;
    } catch (error) {
      console.error('Admin login failed:', error);
      throw error;
    }
  },
};

export default AdminController;
