import React from 'react';
import './Contact.css';

const Contact = () => {
    return (
        <div className="contact-container">
            <div className="contact-info-page">
                <h2>Bizimlə Əlaqə</h2>
                <p>Suallarınız var? Bizimlə aşağıdakı məlumatlarla əlaqə saxlaya bilərsiniz.</p>

                <div className="contact-item">
                    <strong>Email:</strong>{' '}
                    <a href="mailto:info@mototaksi.az">info@mototaksi.az</a>
                </div>

                <div className="contact-item">
                    <strong>Telefon:</strong>{' '}
                    <a href="tel:+994709559984">+994 70 955 99 84</a>
                </div>

                <div className="contact-item">
                    <strong>Whatsapp:</strong>{' '}
                    <a href="https://wa.link/2k345n" target="_blank" rel="noopener noreferrer">
                        +994 70 955 99 84
                    </a>
                </div>

                <div className="contact-links">
                    <a
                        href="https://chat.whatsapp.com/Lg1Emr5KfHeCagALQDg3sZ"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        📲 Whatsapp Qrupumuza Qoşul
                    </a>
                    <a href="tel:+994709559984">📞 Birbaşa Əlaqə</a>
                    <a href="mailto:info@mototaksi.az">✉️ Email Göndər</a>
                </div>
            </div>
        </div>
    );
};

export default Contact;
