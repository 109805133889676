import React, { useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import AnaSehife from './screens/AnaSehife';
import Auth from './screens/auth/Auth';
import Chat from './screens/chat/Chat';
import ElanYarat from './screens/elanlar/ElanYarat';
import Profile from './screens/profile/Profile';
import MotoTaksiSifarish from './screens/sifarish/MotoTaksiSifarish';
import Elanlar from './screens/elanlar/Elanlar';
import Elan from './screens/elanlar/Elan';
import EditElan from './screens/elanlar/EditElan';
import Dashboard from './screens/private/Dashboard';
import AdminLogin from './screens/private/auth/AdminLogin';
import ElanlarAdmin from './screens/private/elanlar/ElanlarAdmin';
import ElanAdmin from './screens/private/elanlar/ElanAdmin';
import EditElanAdmin from './screens/private/elanlar/EditElanAdmin';
import IstifadecilerAdmin from './screens/private/users/IstifadecilerAdmin';
import MotoTaksiSifarishAdmin from './screens/private/sifarish/MotoTaksiSifarishAdmin';
import ChatAdmin from './screens/private/chat/ChatAdmin';
import Contact from './screens/contact/Contact';
import About from './screens/about/About';

const AppLayout = () => {
  const location = useLocation();
  const isChatPage = location.pathname === '/chat';
  const isAuthenticated = localStorage.getItem('motoTaksiUserId'); // User ID localStorage-də yoxlanır
  const isAdminAuthenticated = localStorage.getItem('motoTaksiAdminId'); // Admin ID yoxlanır

  // Istifadəçi login olub olmadığını yoxlayırıq
  useEffect(() => {
    if (!isAuthenticated && ['/chat', '/elanyarat', '/profile', '/motosifarish', '/edit-elan'].includes(location.pathname)) {
      window.location.href = "/auth"; // Yönləndiririk /auth səhifəsinə
    }
  }, [isAuthenticated, location.pathname]);

  // Navbar və Footer yalnız bu səhifələrdə göstərilməyəcək
  const isAdminPage =
    location.pathname === '/admin-login' ||
    location.pathname === '/private' ||
    location.pathname === '/private/elanlar' ||
    location.pathname.startsWith('/private/elan/') ||
    location.pathname.startsWith('/private/edit-elan/') ||
    location.pathname === '/private/istifadeciler' ||
    location.pathname === '/private/sifarishler' ||
    location.pathname === '/private/chat';

  return (
    <>
      {!isAdminPage && !isChatPage && <Navbar />}

      <Routes>
        <Route path="/" element={<AnaSehife />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/elaqe" element={<Contact />} />
        <Route path="/haqqimizda" element={<About />} />
        <Route path="/chat" element={isAuthenticated ? <Chat /> : <Navigate to="/auth" />} />
        <Route path="/elanyarat" element={isAuthenticated ? <ElanYarat /> : <Navigate to="/auth" />} />
        <Route path="/profile" element={isAuthenticated ? <Profile /> : <Navigate to="/auth" />} />
        <Route path="/profile/sifarishlerim" element={isAuthenticated ? <Profile /> : <Navigate to="/auth" />} />
        <Route path="/profile/elanlarim" element={isAuthenticated ? <Profile /> : <Navigate to="/auth" />} />
        <Route path="/motosifarish" element={isAuthenticated ? <MotoTaksiSifarish /> : <Navigate to="/auth" />} />
        <Route path="/elanlar/:category" element={<Elanlar />} />
        <Route path="/elan/:id" element={<Elan />} />
        <Route path="/edit-elan/:id" element={isAuthenticated ? <EditElan /> : <Navigate to="/auth" />} />

        {/* Admin login səhifəsi */}
        <Route
          path="/admin-login"
          element={isAdminAuthenticated ? <Navigate to="/private" /> : <AdminLogin />}
        />

        {/* Admin Dashboard */}
        <Route
          path="/private"
          element={isAdminAuthenticated ? <Dashboard /> : <Navigate to="/admin-login" />}
        />

        {/* Admin elanlar səhifəsi */}
        <Route
          path="/private/elanlar"
          element={isAdminAuthenticated ? <ElanlarAdmin /> : <Navigate to="/" />}
        />

        <Route
          path="/private/elan/:id"
          element={isAdminAuthenticated ? <ElanAdmin /> : <Navigate to="/" />}
        />

        <Route
          path="/private/edit-elan/:id"
          element={isAdminAuthenticated ? <EditElanAdmin /> : <Navigate to="/" />}
        />

        <Route
          path="/private/istifadeciler"
          element={isAdminAuthenticated ? <IstifadecilerAdmin /> : <Navigate to="/" />}
        />

        <Route
          path="/private/istifadeciler/:userId"
          element={isAdminAuthenticated ? <IstifadecilerAdmin /> : <Navigate to="/" />}
        />


        <Route
          path="/private/sifarishler"
          element={isAdminAuthenticated ? <MotoTaksiSifarishAdmin /> : <Navigate to="/" />}
        />

        <Route
          path="/private/chat"
          element={isAdminAuthenticated ? <ChatAdmin /> : <Navigate to="/" />}
        />


        {/* Mövcud olmayan səhifələr üçün ana səhifəyə yönləndirmək */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

      {!isAdminPage && !isChatPage && <Footer />}
    </>
  );
};

export default AppLayout;
