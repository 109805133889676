import React, { useState, useEffect } from 'react';
import ElanCard from '../components/ElanCard';
import './AnaSehife.css';
import { BsChatDots } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import ElanController from '../api/elan/ElanController'; // ElanController'ı import et
import UserController from '../api/user/UserController';

const AnaSehife = () => {
  const [filteredElans, setFilteredElans] = useState([]); // Filterlənmiş elanlar
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(true); // Yüklənmə vəziyyəti
  // eslint-disable-next-line no-unused-vars
  const [premiumElans, setPremiumElans] = useState([]); // Premium elanlar
  // eslint-disable-next-line no-unused-vars
  const [normalElans, setNormalElans] = useState([]); // Normal elanlar
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/chat');
  };

  useEffect(() => {
    const checkUserStatus = async () => {
      const userId = localStorage.getItem('motoTaksiUserId');
      const currentPassword = localStorage.getItem('currentPassword');

      if (userId && currentPassword) {
        try {
          const user = await UserController.getUserById(userId); // ElanController əvəzinə UserController

          if (user.isDisable) {
            alert('Hesabınız administrator tərəfindən bloklanıb.');
            localStorage.clear();
            navigate('/auth');
            return;
          }

          if (user.password !== currentPassword) {
            alert('Şifrəniz dəyişdirilib və ya yanlışdır. Zəhmət olmasa yenidən daxil olun.');
            localStorage.clear();
            navigate('/auth');
            return;
          }

        } catch (error) {
          console.error('İstifadəçi yoxlanarkən xəta:', error);
          localStorage.clear();
          navigate('/auth');
        }
      }
    };

    checkUserStatus();

    // Premium və normal elanları yükləyirik
    const loadElans = async () => {
      try {
        const response = await ElanController.getAllElans();

        const premiumElans = response.filter(elan => elan.premium);
        const normalElans = response.filter(elan => !elan.premium);

        // Premium elanları tarixinə görə azalan (en yeni ilk) sıralama
        premiumElans.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        // Normal elanları da tarixinə görə sıralama
        normalElans.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        setPremiumElans(premiumElans);
        setNormalElans(normalElans);

        // Əgər premium elanlar 10-dan azdırsa, normal elanları əlavə edirik
        let allElans = [...premiumElans];
        if (premiumElans.length < 10) {
          const additionalElans = normalElans.slice(0, 10 - premiumElans.length);
          allElans = [...allElans, ...additionalElans];
        }

        setFilteredElans(allElans); // Həm premium, həm də əlavə edilən normal elanları göstəririk
      } catch (error) {
        console.error('Elanlar yüklənərkən xəta:', error);
      } finally {
        setIsLoading(false);
      }
    };


    loadElans();
  }, [navigate]);

  // Kategoriyaya görə filterləmə funksiyası
  const handleCategoryClick = (category) => {
    navigate(`/elanlar/${category}`);  // Kategoriyanı göstərəcək səhifəyə yönləndiririk
  };

  // Elan kartına tıklananda bu metod işə düşəcək
  const handleElanClick = (id) => {
    navigate(`/elan/${id}`);  // Elanın id-sinə əsasən URL-dəki səhifəyə yönləndiririk
  };

  // Daha çox elanı yükləmək üçün
  const loadMore = (category) => {
    navigate(`/elanlar/${category}`);  // Kategoriyanın tam səhifəsini göstər
  };

  return (
    <div className="ana-container">
      {/* Kateqoriya */}
      <div className="category-container">
        <div className="category-card" onClick={() => handleCategoryClick('taxi')}>
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqNipI0BHsZ5uauyUnyIAP3Ecnev7QTXJV-Q&s" alt="Moto Taksi" />
          <span>Moto Taksi Elanları</span>
        </div>

        <div className="category-card" onClick={() => handleCategoryClick('sales')}>
          <img src="https://images.ctfassets.net/8zlbnewncp6f/4RxbRFRNzlhu2RJfqBgeF7/e472da1e62b85dd6c55fb5845eafe0a5/bajaj-pulsar-n250-primer-plano.jpg?w=600&fm=webp&q=90" alt="Alqı-Satqı" />
          <span>Moto Alqı-Satqı Elanları</span>
        </div>

        <div className="category-card" onClick={() => handleCategoryClick('rent')}>
          <img src="https://www.motorentpujols.com/wp-content/uploads/2023/07/VESPA-e1688664789488.jpg" alt="Moto Kirayə" />
          <span>Moto Kirayə Elanları</span>
        </div>

        <div className="category-card" onClick={() => handleCategoryClick('spare')}>
          <img src="https://s.alicdn.com/@sc04/kf/H80485c4ee5fb4557a4d23e9f42dcc41bU.jpg_300x300.jpg" alt="Moto Zapçast" />
          <span>Moto Zapçast Elanları</span>
        </div>
      </div>

      {/* Moto Taksi Elanları */}
      <h2 className="section-title">Moto Taksi Elanları</h2>
      <div className="elans-container">
        {filteredElans
          .filter(elan => elan.category === 'taxi')
          .map((elan, index) => (
            <ElanCard
              key={`taxi-${index}`}
              {...elan}
              title={
                elan.title.length > 25
                  ? elan.title.slice(0, 25) + '...'
                  : elan.title
              }
              description={
                elan.description.length > 30
                  ? elan.description.slice(0, 30) + '...'
                  : elan.description
              }
              onClick={() => handleElanClick(elan.id)}
            />

          ))}
      </div>
      <button className="load-more" onClick={() => loadMore('taxi')}>Daha çox</button>


      {/* Moto Alqı-Satqı Elanları */}
      <h2 className="section-title" style={{ marginTop: '50px' }}>Moto Alqı-Satqı Elanları</h2>
      <div className="elans-container">
        {filteredElans
          .filter(elan => elan.category === 'sales')
          .map((elan, index) => (
            <ElanCard
              key={`sales-${index}`}
              {...elan}
              title={
                elan.title.length > 25
                  ? elan.title.slice(0, 25) + '...'
                  : elan.title
              }
              description={
                elan.description.length > 30
                  ? elan.description.slice(0, 30) + '...'
                  : elan.description
              }
              onClick={() => handleElanClick(elan.id)}
            />

          ))}
      </div>
      <button className="load-more" onClick={() => loadMore('sales')}>Daha çox</button>

      {/* Moto Kirayə Elanları */}
      <h2 className="section-title" style={{ marginTop: '50px' }}>Moto Kirayə Elanları</h2>
      <div className="elans-container">
        {filteredElans
          .filter(elan => elan.category === 'rent')
          .map((elan, index) => (
            <ElanCard
              key={`rent-${index}`}
              {...elan}
              title={
                elan.title.length > 25
                  ? elan.title.slice(0, 25) + '...'
                  : elan.title
              }
              description={
                elan.description.length > 30
                  ? elan.description.slice(0, 30) + '...'
                  : elan.description
              }
              onClick={() => handleElanClick(elan.id)}
            />

          ))}
      </div>
      <button className="load-more" onClick={() => loadMore('rent')}>Daha çox</button>

      {/* Moto Zapçast Elanları */}
      <h2 className="section-title" style={{ marginTop: '50px' }}>Moto Zapçast Elanları</h2>
      <div className="elans-container">
        {filteredElans
          .filter(elan => elan.category === 'spare')
          .map((elan, index) => (
            <ElanCard
              key={`spare-${index}`}
              {...elan}
              title={
                elan.title.length > 25
                  ? elan.title.slice(0, 25) + '...'
                  : elan.title
              }
              description={
                elan.description.length > 30
                  ? elan.description.slice(0, 30) + '...'
                  : elan.description
              }
              onClick={() => handleElanClick(elan.id)}
            />

          ))}
      </div>
      <button className="load-more" style={{ marginBottom: '100px' }} onClick={() => loadMore('spare')}>Daha çox</button>

      {/* Chat Button */}
      <div className="whatsapp-float" onClick={handleClick}>
        <button className="whatsapp-button">
          <BsChatDots />
        </button>
      </div>
    </div>
  );
};

export default AnaSehife;
