import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';
import ElanController from '../../api/elan/ElanController';
import UserController from '../../api/user/UserController';
import { getAllChats } from '../../api/sifarish/SifarishController';


const Dashboard = () => {
  const navigate = useNavigate();
  const [elanCount, setElanCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [sifarishCount, setSifarishCount] = useState(0);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const elans = await ElanController.getAllElans();
        setElanCount(elans.length);

        const users = await UserController.getAllUsers();
        setUserCount(users.length);

        const chats = await getAllChats();
        const websiteOrders = chats.filter(chat => chat.isWebsite === true);
        setSifarishCount(websiteOrders.length);


      } catch (error) {
        console.error("Saylar yüklənərkən xəta:", error);
      }
    };

    fetchCounts();
  }, []);



  const handleCardClick = (path) => {
    navigate(path);
  };

  const handleLogout = () => {
    const confirmLogout = window.confirm("Çıxış etmək istəyirsiniz?");
    if (confirmLogout) {
      localStorage.removeItem('motoTaksiAdminId');
      navigate('/admin-login');
    }
  };

  return (
    <div className="dashboard-container">
      <h1 className="dashboard-title">Admin Panel</h1>

      <div className="dashboard-cards">
        <div className="dashboard-card" onClick={() => handleCardClick('/private/elanlar')}>
          <div className="card-header">
            <i className="fas fa-list-alt"></i>
            <span>Elanlar</span>
          </div>
          <div className="card-content">
            <p>{elanCount} Elan</p>
          </div>
        </div>

        {/* Digər kartlar (ehtiyac varsa dinamik hala da gətirə bilərik) */}
        <div className="dashboard-card" onClick={() => handleCardClick('/private/sifarishler')}>
          <div className="card-header">
            <i className="fas fa-taxi"></i>
            <span>Moto Taksi Sifarişləri</span>
          </div>
          <div className="card-content">
            <p>{sifarishCount} Sifariş</p>
          </div>

        </div>

        <div className="dashboard-card" onClick={() => handleCardClick('/private/chat')}>
          <div className="card-header">
            <i className="fas fa-comments"></i>
            <span>Çat</span>
          </div>
        </div>

        <div className="dashboard-card" onClick={() => handleCardClick('/private/istifadeciler')}>
          <div className="card-header">
            <i className="fas fa-users"></i>
            <span>İstifadəçilər</span>
          </div>
          <div className="card-content">
            <p>{userCount} İstifadəçi</p>
          </div>
        </div>

      </div>

      <button className="logout-button" onClick={handleLogout}>
        Çıxış Et
      </button>
    </div>
  );
};

export default Dashboard;
