// src/api/UserController.js

import axios from 'axios';

const BASE_URL = 'https://mototaksi.az:9999/api/v6/user';
const LOGIN_URL = 'https://mototaksi.az:9999/api/v6/login/user';

const UserController = {
  // Bütün istifadəçiləri gətir
  getAllUsers: async () => {
    try {
      const response = await axios.get(BASE_URL);
      return response.data;
    } catch (error) {
      console.error('İstifadəçilər alınarkən xəta:', error);
      throw error;
    }
  },

  // ID-yə görə istifadəçini gətir
  getUserById: async (userId) => {
    try {
      const response = await axios.get(`${BASE_URL}/${userId}`);
      return response.data;
    } catch (error) {
      console.error(`ID ${userId} üçün istifadəçi alınarkən xəta:`, error);
      throw error;
    }
  },

  // Yeni istifadəçi yarat
  createUser: async (userData) => {
    try {
      const response = await axios.post(BASE_URL, userData);
      return response.data;
    } catch (error) {
      console.error('İstifadəçi yaradılarkən xəta:', error);
      throw error;
    }
  },

  // İstifadəçini yenilə
  updateUser: async (userId, userData) => {
    try {
      const response = await axios.put(`${BASE_URL}/${userId}`, userData);
      return response.data;
    } catch (error) {
      console.error(`İstifadəçi ID ${userId} yenilənərkən xəta:`, error);
      throw error;
    }
  },

  // İstifadəçini sil
  deleteUserById: async (userId) => {
    try {
      const response = await axios.delete(`${BASE_URL}/${userId}`);
      return response.data;
    } catch (error) {
      console.error(`İstifadəçi ID ${userId} silinərkən xəta:`, error);
      throw error;
    }
  },

  // İstifadəçi login
  loginUser: async (loginData) => {
    try {
      const response = await axios.post(LOGIN_URL, loginData);
      return response.data;
    } catch (error) {
      console.error('Login zamanı xəta:', error);
      throw error;
    }
  },
};

export default UserController;
