import React from 'react';
import moment from 'moment';
import 'moment/locale/az';
import './ElanCard.css';

moment.locale('az');

const formatDate = (createdAt) => {
  const now = moment();
  const created = moment(createdAt);

  if (created.isSame(now, 'day')) {
    return `Bugün, ${created.format('HH:mm')}`;
  } else if (created.isSame(now.clone().subtract(1, 'days'), 'day')) {
    return `Dünən, ${created.format('HH:mm')}`;
  } else {
    return created.format('LL, HH:mm');
  }
};

const ElanCard = ({ image, price, title, description, createdAt, premium, onClick, username, userId, onUsernameClick }) => {
  return (
    <div className={`card ${premium ? 'premium' : ''}`} onClick={onClick}>
      <div className="card-image">
        <img src={image} alt={title} />
        {premium && <div className="premium-badge">PREMIUM</div>}
      </div>
      <div className="card-info">
        <div className="price">{price} ₼</div>
        <div className="title">{title}</div>
        <div className="desc">{description}</div>
        <div className="meta">
          <span>{formatDate(createdAt)}</span>
        </div>

        {/* Username düyməsini əlavə edirik */}
        {username && (
          <button 
            className="card-username" 
            onClick={(e) => { 
              e.stopPropagation(); // Düymə klikləmə zamanı kartın kliklənməsini qarşısını alır
              onUsernameClick(userId); // userId-ni göndəririk
            }}
          >
            {username}
          </button>
        )}
      </div>
    </div>
  );
};


export default ElanCard;
