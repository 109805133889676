import axios from 'axios';

const BASE_URL = 'https://mototaksi.az:9999/api/v6/elan';

const ElanController = {
  // Bütün elanları gətir
  getAllElans: async () => {
    try {
      const response = await axios.get(BASE_URL);
      return response.data;
    } catch (error) {
      console.error('Elanlar alınarkən xəta:', error);
      throw error;
    }
  },

  // Kullanıcıya ait ilanları al
  getElansByUserId: async (userId) => {
    try {
      // İlk olarak tüm ilanları al
      const allElans = await ElanController.getAllElans();
      
      // İlanları, kullanıcının userId'sine göre filtrele
      const userElans = allElans.filter(elan => elan.userId === parseInt(userId));

      return userElans;
    } catch (error) {
      console.error('User ID ile ilanlar alınarkən xəta:', error);
      throw error;
    }
  },

  // ID-yə görə elan gətir
  getElanById: async (elanId) => {
    try {
      const response = await axios.get(`${BASE_URL}/${elanId}`);
      return response.data;
    } catch (error) {
      console.error(`ID ${elanId} üçün elan alınarkən xəta:`, error);
      throw error;
    }
  },

  // Yeni elan yarat
  createElan: async (elanData) => {
    try {
      const response = await axios.post(BASE_URL, elanData);
      return response.data;
    } catch (error) {
      console.error('Elan yaradılarkən xəta:', error);
      throw error;
    }
  },

  // Elanı yenilə
  updateElan: async (elanId, elanData) => {
    try {
      const response = await axios.put(`${BASE_URL}/${elanId}`, elanData);
      return response.data;
    } catch (error) {
      console.error(`Elan ID ${elanId} yenilənərkən xəta:`, error);
      throw error;
    }
  },

  // Elanı sil
  deleteElanById: async (elanId) => {
    try {
      const response = await axios.delete(`${BASE_URL}/${elanId}`);
      return response.data;
    } catch (error) {
      console.error(`Elan ID ${elanId} silinərkən xəta:`, error);
      throw error;
    }
  },
};

export default ElanController;
