import React from 'react';
import '../components/Footer.css';
import { FaHome, FaHeart, FaPlus, FaComments, FaUser } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();

  const isAuthenticated = localStorage.getItem('motoTaksiUserId'); // Admin ID yoxlanır

  // Navigasiya funksiyaları
  const handleHomeClick = () => {
    navigate('/');
  };

  const handleSifarishClick = () => {
    if (!isAuthenticated) {
      navigate('/auth'); // Giriş etməmişsə /auth səhifəsinə yönləndiririk
    } else {
      navigate('/motosifarish');
    }
  }

  const handleElanClick = () => {
    if (!isAuthenticated) {
      navigate('/auth'); // Giriş etməmişsə /auth səhifəsinə yönləndiririk
    } else {
      navigate('/elanyarat');
    }
  };

  const handleChatClick = () => {
    if (!isAuthenticated) {
      navigate('/auth'); // Giriş etməmişsə /auth səhifəsinə yönləndiririk
    } else {
      navigate('/chat');
    }
  };

  const handleProfileClick = () => {
    if (!isAuthenticated) {
      navigate('/auth'); // Giriş etməmişsə /auth səhifəsinə yönləndiririk
    } else {
      navigate('/profile');
    }
  };

  return (
    <footer className="footer">
      {/* Desktop Footer */}
      <div className="footer-desktop-nav">
        <div className="footer-left">MotoTaksi.az</div>
        <div className="footer-center">
          © {new Date().getFullYear()} Bütün hüquqlar qorunur.
        </div>
        <div className="footer-right">
          <a href="/" className="footer-link">Əsas səhifə</a>
          <a href="/contact" className="footer-link">Əlaqə</a>
          <a href="/terms" className="footer-link">Şərtlər</a>
        </div>
      </div>

      {/* Mobile Footer */}
      <div className="footer-mobile-nav">
        <div className={`footer-icon ${currentPath === '/' ? 'active' : ''}`} onClick={handleHomeClick}>
          <FaHome />
          <span>Əsas səhifə</span>
        </div>

        <div className={`footer-icon ${currentPath === '/motosifarish' ? 'active' : ''}`} onClick={handleSifarishClick}>
          <FaHeart />
          <span>Sifariş et</span>
        </div>

        <div className={`footer-icon ${currentPath === '/elanyarat' ? 'active' : ''}`} onClick={handleElanClick}>
          <FaPlus />
          <span>Elan Yarat</span>
        </div>

        <div className={`footer-icon ${currentPath === '/chat' ? 'active' : ''}`} onClick={handleChatClick}>
          <FaComments />
          <span>Çat</span>
        </div>

        <div className={`footer-icon ${currentPath === '/profile' ? 'active' : ''}`} onClick={handleProfileClick}>
          <FaUser />
          <span>Profil</span>
        </div>

      </div>
    </footer>
  );
};

export default Footer;
