import axios from 'axios';
import { Client } from '@stomp/stompjs';

// Base URL
const BASE_URL = 'https://mototaksi.az:9999/api';

// Axios instance
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// 🟩 HTTP Requests

export const getAllChats = async () => {
  const response = await api.get('/chats');
  return response.data;
};

export const getChatById = async (chatId) => {
  const response = await api.get(`/chat/${chatId}`);
  return response.data;
};

export const createChat = async (chatDto) => {
  const response = await api.post('/chat', chatDto);
  return response.data;
};

export const updateChat = async (chatId, chatDto) => {
  const response = await api.put(`/chat/${chatId}`, chatDto);
  return response.data;
};

export const deleteChat = async (chatId) => {
  const response = await api.delete(`/chat/${chatId}`);
  return response.data;
};

let stompClient = null;

export const connectWebSocket = (onMessageCallback, onConnectedCallback) => {
  stompClient = new Client({
    brokerURL: 'wss://mototaksi.az:9999/ws',
    connectHeaders: {},
    reconnectDelay: 5000,
    onConnect: () => {
      console.log('✅ WebSocket bağlıdır');
      stompClient.subscribe('/topic/mototaksiwebapi', (message) => {
        const body = JSON.parse(message.body);
        onMessageCallback(body);
      });

      if (onConnectedCallback) onConnectedCallback(); // burada çağırırıq
    },
    onStompError: (frame) => {
      console.error('STOMP xəta:', frame);
    },
  });

  stompClient.activate();
};

export const sendMessage = (chatMessageDto) => {
  if (stompClient && stompClient.connected) {
    stompClient.publish({
      destination: '/app/sendMotoMessage',
      body: JSON.stringify(chatMessageDto),
    });
  } else {
    console.error('WebSocket not connected');
  }
};

export const disconnectWebSocket = () => {
  if (stompClient) {
    stompClient.deactivate();
  }
};
