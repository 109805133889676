import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ElanController from '../../api/elan/ElanController';
import './EditElan.css';
import Loader from '../../components/Loader';

const IMG_BB_API_KEYS = [
    '4b8d7989751c89d9561fd1e44100bf68',
    '2c5257b8de4e5bf05fc5a7b2419a418e',
    '62346dd09fb59412e2774c150742d3a4',
];

const EditElan = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [form, setForm] = useState({
        title: '',
        description: '',
        image: '',
        imageFile: null,
        price: '',
    });

    const [preview, setPreview] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const currentUserId = parseInt(localStorage.getItem('motoTaksiUserId'));

    useEffect(() => {
        const loadElan = async () => {
            try {
                const data = await ElanController.getElanById(id);

                if (data.userId !== currentUserId) {
                    alert('Bu elan sizə məxsus deyil!');
                    navigate('/');
                    return;
                }

                setForm({
                    title: data.title || '',
                    description: data.description || '',
                    image: data.image || '',
                    imageFile: null,
                    price: data.price || '',
                });


                setPreview(data.image || null);

                // Süni gecikmə - ən az 2 saniyə gözləsin
                await new Promise((resolve) => setTimeout(resolve, 2000));
            } catch (error) {
                console.error('Elan yüklənərkən xəta:', error);
                alert('Elan tapılmadı');
                navigate('/');
            } finally {
                setIsLoading(false);
            }
        };

        loadElan();
    }, [id, currentUserId, navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setForm({ ...form, imageFile: file });

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setPreview(null);
        }
    };

    const uploadImageToImgBB = async (file) => {
        const apiUrl = 'https://api.imgbb.com/1/upload';
        for (let key of IMG_BB_API_KEYS) {
            try {
                const formData = new FormData();
                formData.append('key', key);
                formData.append('image', file);

                const response = await fetch(apiUrl, {
                    method: 'POST',
                    body: formData,
                });
                const data = await response.json();
                if (data.success) {
                    return data.data.url;
                }
            } catch (error) {
                console.warn(`⚠️ API açarı uğursuz oldu: ${key}`);
            }
        }
        throw new Error("Şəkil yüklənə bilmədi.");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const startTime = Date.now(); // Yükləmənin başlama vaxtını qeyd et

        try {
            let imageUrl = form.image;

            if (form.imageFile) {
                imageUrl = await uploadImageToImgBB(form.imageFile);
            }

            const updateData = {
                title: form.title,
                description: form.description,
                image: imageUrl,
                price: form.price,
                updatedAt: new Date().toISOString(),
            };


            await ElanController.updateElan(id, updateData);

            const elapsed = Date.now() - startTime;
            const delay = Math.max(2000 - elapsed, 0); // Əgər 2 saniyə keçməyibsə, gözlət

            setTimeout(() => {
                alert("Elan uğurla yeniləndi!");
                navigate('/profile');
            }, delay);
        } catch (error) {
            console.error("Yeniləmə zamanı xəta:", error);
            alert("Elan yenilənə bilmədi.");
            setIsLoading(false); // Xətada dərhal loader bağlanır
        }
    };


    return (
        <div className="elan-container">
            <h2>Elanı Redaktə Et</h2>
            <form className="elan-form" onSubmit={handleSubmit}>
                <label htmlFor="title">Başlıq</label>
                <input
                    type="text"
                    id="title"
                    name="title"
                    placeholder="Başlıq"
                    value={form.title}
                    onChange={handleChange}
                    required
                />

                <label htmlFor="description">Açıqlama</label>
                <textarea
                    id="description"
                    name="description"
                    placeholder="Açıqlama"
                    value={form.description}
                    onChange={handleChange}
                    required
                ></textarea>

                <label htmlFor="price">Qiymət (AZN)</label>
                <input
                    type="number"
                    id="price"
                    name="price"
                    placeholder="Qiymət"
                    value={form.price}
                    onChange={handleChange}
                    required
                />

                <label htmlFor="image-upload">Yeni şəkil seçin (əgər dəyişmək istəyirsinizsə)</label>
                <input
                    type="file"
                    id="image-upload"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleImageChange}
                />
                <label htmlFor="image-upload" className="file-label upload-btn">Şəkil Yüklə</label>

                {preview && (
                    <div className="image-preview">
                        <img src={preview} alt="Preview" />
                    </div>
                )}

                <button type="submit" disabled={isLoading}>
                    {isLoading ? 'Yüklənir...' : 'Yadda saxla'}
                </button>
            </form>


            {isLoading && <Loader />}
        </div>
    );
};

export default EditElan;
