import React from 'react';
import './Navbar.css';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();

  const userId = localStorage.getItem('motoTaksiUserId');
  const isLoggedIn = !!userId;

  const handleLoginClick = () => {
    if (isLoggedIn) {
      navigate('/profile');
    } else {
      navigate('/auth');
    }
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  const handlePostClick = () => {
    if (isLoggedIn) {
      navigate('/elanyarat');
    } else {
      navigate('/auth');
    }
  };

  const handleMotoClick = () => {
    if (isLoggedIn) {
      navigate('/motosifarish');
    } else {
      navigate('/auth');
    }
  };

  const handleAboutClick = () => {
    navigate('/haqqimizda');
  };

  const handleContactClick = () => {
    navigate('/elaqe');
  };

  return (
    <nav className="navbar">
      <div className="navbar-content">
        <div className="logo" onClick={handleLogoClick}>MotoTaksi.az</div>
        <div className="navbar-buttons">
          <button className="login-btn" onClick={handleLoginClick}>
            {isLoggedIn ? 'Hesabım' : 'Daxil Ol'}
          </button>
          <button className="post-btn" onClick={handlePostClick}>Pulsuz Elan Yerləşdir</button>
          <button className="moto-btn" onClick={handleMotoClick}>Moto Taksi Sifariş Et</button>
          <button className="about-btn" onClick={handleAboutClick}>Haqqımızda</button>
          <button className="contact-btn" onClick={handleContactClick}>Əlaqə</button>
          <a
            className="whatsapp-btn"
            href="https://chat.whatsapp.com/Lg1Emr5KfHeCagALQDg3sZ"
            target="_blank"
            rel="noopener noreferrer"
          >
            Qrupa Qoşul
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
