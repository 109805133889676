import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UserController from '../../../api/user/UserController';
import './IstifadecilerAdmin.css';

const IstifadecilerAdmin = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { userId } = useParams(); // path: /private/istifadeciler/:userId
    const userRefs = useRef({});

    const handleEditClick = (user) => {
        setSelectedUser(user);
        setIsModalOpen(true);
    };


    const handleModalChange = (e) => {
        const { name, value } = e.target;
        setSelectedUser(prev => ({ ...prev, [name]: value }));
    };

    const handleSaveChanges = async () => {
        try {
            await UserController.updateUser(selectedUser.id, selectedUser);
            setUsers(prev =>
                prev.map(u => (u.id === selectedUser.id ? selectedUser : u))
            );
            setIsModalOpen(false);
            alert("Məlumatlar yeniləndi.");
        } catch (error) {
            console.error("Yeniləmə zamanı xəta:", error);
            alert("Məlumatlar yenilənə bilmədi.");
        }
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const data = await UserController.getAllUsers();
                const sortedUsers = data.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
                setUsers(sortedUsers);
            } catch (error) {
                console.error('İstifadəçilər yüklənərkən xəta:', error);
            }
        };

        fetchUsers();
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    const toggleUserStatus = async (userId, currentStatus) => {
        try {
            const updatedUser = {
                isDisable: !currentStatus,
            };
            await UserController.updateUser(userId, updatedUser);

            setUsers((prev) =>
                prev.map((user) =>
                    user.id === userId ? { ...user, isDisable: !currentStatus } : user
                )
            );
        } catch (error) {
            console.error('Status dəyişdirilərkən xəta:', error);
        }
    };

    const filteredUsers = users.filter(user =>
        user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.phoneNumber.includes(searchQuery) ||
        user.email.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // sonra:
    useEffect(() => {
        if (userId && userRefs.current[userId]) {
            userRefs.current[userId].scrollIntoView({ behavior: 'smooth', block: 'center' });
            userRefs.current[userId].style.border = '2px solid #3b82f6'; // Mavi sərhəd (optional)
        }
    }, [userId, filteredUsers]);



    return (
        <div className="users-container">
            <button className="back-button" onClick={handleBack}>Geri</button>
            <h2 className="users-title">İstifadəçilər Siyahısı</h2>

            <input
                type="text"
                className="search-input"
                placeholder="Axtar: ad, nömrə və ya email"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />

            <div className="users-list">
                {filteredUsers.map(user => (
                    <div
                        key={user.id}
                        className="user-card"
                        ref={(el) => (userRefs.current[user.id] = el)}
                    >

                        <h3>{user.name}</h3>
                        <p><strong>Nömrə:</strong> {user.phoneNumber}</p>
                        <p><strong>Email:</strong> {user.email}</p>
                        <p><strong>Şifrə:</strong> {user.password}</p>
                        <p><strong>Qeydiyyat:</strong> {user.createdDate}</p>
                        <p><strong>Status:</strong> {user.isDisable ? 'Deaktiv' : 'Aktiv'}</p>
                        <div className="card-actions">
                            <button className="edit-button" onClick={() => handleEditClick(user)}>Düzəlt</button>
                            <button
                                className="block-button"
                                onClick={() => toggleUserStatus(user.id, user.isDisable)}
                            >
                                {user.isDisable ? 'Blokdan çıxart' : 'Blokla'}
                            </button>
                        </div>
                    </div>
                ))}
                {filteredUsers.length === 0 && <p className="no-users">Heç bir istifadəçi tapılmadı.</p>}
            </div>

            {isModalOpen && selectedUser && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h3>İstifadəçini Redaktə Et</h3>

                        <label htmlFor="name">Ad</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={selectedUser.name}
                            onChange={handleModalChange}
                            placeholder="Ad"
                        />

                        <label htmlFor="phoneNumber">Nömrə</label>
                        <input
                            type="text"
                            id="phoneNumber"
                            name="phoneNumber"
                            value={selectedUser.phoneNumber}
                            onChange={handleModalChange}
                            placeholder="Nömrə"
                        />

                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={selectedUser.email}
                            onChange={handleModalChange}
                            placeholder="Email"
                        />

                        <label htmlFor="password">Şifrə</label>
                        <input
                            type="text"
                            id="password"
                            name="password"
                            value={selectedUser.password}
                            onChange={handleModalChange}
                            placeholder="Şifrə"
                        />

                        <div className="modal-actions">
                            <button className="save-button" onClick={handleSaveChanges}>Yadda saxla</button>
                            <button className="cancel-button" onClick={() => setIsModalOpen(false)}>Ləğv et</button>
                        </div>
                    </div>
                </div>
            )}



        </div>
    );
};

export default IstifadecilerAdmin;
