import React, { useState, useEffect, useRef } from 'react';
import './Profile.css';
import ElanCard from '../../components/ElanCard';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAllChats, updateChat, deleteChat } from '../../api/sifarish/SifarishController';
import ElanController from '../../api/elan/ElanController';
import UserController from '../../api/user/UserController';

const Profile = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [activeCategory, setActiveCategory] = useState('all');
  const [user, setUser] = useState(null);
  const [myElans, setMyElans] = useState([]);
  const [mySifarishler, setMySifarishler] = useState([]);
  const [showModal, setShowModal] = useState(false); // For showing the modal
  const navigate = useNavigate();
  const sifarishlerRef = useRef(null); // Reference to the "Moto Taksi Sifarişlərim" section
  const elanlarRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const userId = localStorage.getItem('motoTaksiUserId');
    if (userId) {
      UserController.getUserById(userId)
        .then(data => {
          setUser(data);
          ElanController.getElansByUserId(userId)
            .then(elans => setMyElans(elans.reverse()))
            .catch(error => console.error('Elanlar yüklənmədi:', error));

          getAllChats()
            .then(allChats => {
              const userChats = allChats.filter(chat =>
                chat.userId === parseInt(userId) && chat.isWebsite === true
              );
              setMySifarishler(userChats.reverse());
            })
            .catch(error => console.error('Sifarişlər yüklənmədi:', error));
        })
        .catch(error => {
          console.error('İstifadəçi yüklənmədi:', error);
        });
    }
  }, []);

  // Scroll to the "Moto Taksi Sifarişlərim" section when navigating
  useEffect(() => {
    if (location.pathname === '/profile/sifarishlerim' && sifarishlerRef.current) {
      setTimeout(() => {
        window.scrollTo({
          top: sifarishlerRef.current.offsetTop,
          behavior: 'smooth',
        });
        setShowModal(true); // Scroll bitdikdən sonra modal göstərilir
      }, 500); // 500 ms timeout
    }
  }, [location.pathname, mySifarishler]); // Bu kod `sifarishlerRef` bölməsinə getdiyində işə düşəcək

  // Scroll to the "Moto Taksi Elanlarım" section when navigating to /profile/elanlarim
  useEffect(() => {
    if (location.pathname === '/profile/elanlarim' && elanlarRef.current) {
      setTimeout(() => {
        window.scrollTo({
          top: elanlarRef.current.offsetTop,
          behavior: 'smooth',
        });
      }, 500); // 500 ms timeout
    }
  }, [location.pathname]); // Bu kod yalnız /profile/elanlarim səhifəsində işləyəcək


  const handleToggleStatus = async (chat) => {
    try {
      const updated = { ...chat, isCompleted: !chat.isCompleted };
      await updateChat(chat.id, updated);

      setMySifarishler(prev =>
        prev.map(c => c.id === chat.id ? { ...c, isCompleted: !c.isCompleted } : c)
      );

      // Tamamlanmışsa, mesajı göstərmək
      if (!chat.isCompleted) {
        alert("Sifarişiniz tamamlandı!");
      }

    } catch (error) {
      console.error("Status dəyişdirilərkən xəta:", error);
      alert("Status dəyişdirilə bilmədi.");
    }
  };


  const handleDeleteSifarish = async (chatId) => {
    const confirmDelete = window.confirm("Bu sifarişi silmək istədiyinizə əminsiniz?");
    if (confirmDelete) {
      try {
        await deleteChat(chatId);
        setMySifarishler(prev => prev.filter(chat => chat.id !== chatId));
        // Tamamlanmışsa, mesajı göstərmək
        alert("Sifarişiniz silindi!");
      } catch (error) {
        console.error("Silinərkən xəta:", error);
        alert("Sifariş silinə bilmədi.");
      }
    }
  };

  const handlePasswordUpdate = (e) => {
    e.preventDefault();

    if (!oldPassword) {
      alert("Köhnə şifrəni daxil edin");
      return;
    }

    if (user.password !== oldPassword) {
      alert("Köhnə şifrə yanlışdır");
      return;
    }

    if (password !== confirm) {
      alert("Şifrələr uyğun deyil");
      return;
    }

    const updatedUser = { ...user, password: password };
    UserController.updateUser(user.id, updatedUser)
      .then((response) => {
        alert("Şifrə uğurla dəyişdirildi");
        setShowEdit(false);
        setOldPassword('');
        setPassword('');
        setConfirm('');
        setUser(response);
      })
      .catch(error => {
        console.error('Şifrə yenilənərkən xəta:', error);
        alert("Şifrə yenilənərkən xəta baş verdi");
      });
  };

  const handleLogout = () => {
    localStorage.removeItem('motoTaksiUserId');
    window.location.href = '/auth';
  };

  const filteredElans = activeCategory === 'all'
    ? myElans
    : myElans.filter(elan => elan.category === activeCategory);

  const handleElanClick = (elanId) => {
    navigate(`/elan/${elanId}`);
  };

  return (
    <div className="profile-container">
      {user ? (
        <div className="profile-card">
          <h2 className="profile-name">{user.name}</h2>
          <p className="profile-phone">{user.phoneNumber}</p>
          <p className="profile-email">{user.email}</p>

          <div className="profile-actions">
            <button className="edit-btn" onClick={() => setShowEdit(!showEdit)}>
              {showEdit ? 'Ləğv et' : 'Şifrəni dəyiş'}
            </button>
            <button className="logout-btn" onClick={handleLogout}>Çıxış</button>
          </div>

          {showEdit && (
            <form className="password-form" onSubmit={handlePasswordUpdate}>
              <input
                type="password"
                placeholder="Köhnə şifrə"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                required
              />
              <input
                type="password"
                placeholder="Yeni şifrə"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <input
                type="password"
                placeholder="Təsdiqlə"
                value={confirm}
                onChange={(e) => setConfirm(e.target.value)}
                required
              />
              <button type="submit" className="save-btn">Yadda saxla</button>
            </form>
          )}
        </div>
      ) : (
        <div className="loading">İstifadəçi məlumatları yüklənir...</div>
      )}

      {mySifarishler.length > 0 && (
        <div ref={sifarishlerRef} className="my-sifarishler-section">
          <h3>Moto Taksi Sifarişlərim</h3>
          <div className="sifarish-list">
            {mySifarishler.map((chat) => (
              <div key={chat.id} className="sifarish-card">
                <p><strong>Mesaj:</strong> {chat.message}</p>
                <p className="sifarish-time">
                  {new Date(chat.timestamp).toLocaleString('az-AZ', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </p>
                <p className={`sifarish-status ${chat.isCompleted ? 'done' : 'pending'}`}>
                  {chat.isCompleted ? 'Tamamlanıb' : 'Gözləyir'}
                </p>

                <div className="sifarish-actions">
                  {!chat.isCompleted && (
                    <button onClick={() => handleToggleStatus(chat)} className="status-btn">
                      Tamamla
                    </button>
                  )}
                  <button onClick={() => handleDeleteSifarish(chat.id)} className="delete-btn">
                    Sil
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {showModal && location.pathname === '/profile/sifarishlerim' && (
        <div className="notification-modal">
          <div className="notification-content">
            <h4>Diqqət!</h4>
            <p>Aşağıda Sifarişlərinizə kuryer tapıldıqdan sonra sifarişi tamamlamağınız xahiş olunur. Əks halda kuryerlər sizi narahat edəcək.</p>
            <button className="close-btn" onClick={() => setShowModal(false)}>Aydındır</button>
          </div>
        </div>
      )}


      <div ref={elanlarRef} className="my-elans-section">

        <h3>Elanlarım</h3>
        <div className="category-filter">
          <button onClick={() => setActiveCategory('all')}>Hamısı</button>
          <button onClick={() => setActiveCategory('taxi')}>Moto Taksi</button>
          <button onClick={() => setActiveCategory('sales')}>Alqı-Satqı</button>
          <button onClick={() => setActiveCategory('rent')}>Kirayə</button>
          <button onClick={() => setActiveCategory('spare')}>Zapçast</button>
        </div>

        <div className="elans-list-cards">
          {filteredElans.map((elan, index) => (
            <ElanCard key={index} {...elan} onClick={() => handleElanClick(elan.id)} />
          ))}
        </div>

      </div>
    </div>
  );
};

export default Profile;
