import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ElanCard from '../../components/ElanCard';
import ElanController from '../../api/elan/ElanController'; // ElanController'ı import et
import './Elanlar.css';
import Loader from '../../components/Loader'; // Loader komponentini import edirik

const Elanlar = () => {
  const { category } = useParams();  // URL-dən kateqoriyanı alırıq
  const [filteredElans, setFilteredElans] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // initially set to true
  const navigate = useNavigate();  // Initialize useNavigate

  useEffect(() => {
    const loadElans = async () => {
      try {
        const response = await ElanController.getAllElans();

        // Elanları kateqoriyaya görə filterləyirik
        const categoryElans = response.filter(elan => elan.category === category);

        setFilteredElans(categoryElans.reverse()); // Filtrlənmiş elanları qeyd edirik

        // Fake 2 saniyə loading əlavə edirik
        setTimeout(() => {
          setIsLoading(false); // Loader-i 2 saniyə sonra gizlət
        }, 500);
      } catch (error) {
        console.error('Elanlar yüklənərkən xəta:', error);
      }
    };

    loadElans();
  }, [category]);  // category dəyişdikcə bu `useEffect` yenidən işə düşəcək

  // Kateqoriya başlığını təyin etmək üçün
  const getCategoryTitle = (category) => {
    switch (category) {
      case 'taxi':
        return 'Moto Taksi Elanları';
      case 'sales':
        return 'Moto Alqı-Satqı Elanları';
      case 'rent':
        return 'Moto Kirayə Elanları';
      case 'spare':
        return 'Moto Zapçast Elanları';
      default:
        return 'Elanlar';
    }
  };

  // Handle the click event on the ElanCard
  const handleElanClick = (elanId) => {
    navigate(`/elan/${elanId}`);  // Navigate to the individual Elan page with the ID
  };

  return (
    <div className="elanlar-container">
      <h2 className="section-title">{getCategoryTitle(category)}</h2> {/* Kateqoriya başlığını göstəririk */}

      <div className="elans-container">
        {isLoading ? (
          <Loader />
        ) : (
          filteredElans.map((elan, index) => (
            <ElanCard
              key={index}
              {...elan}
              title={
                elan.title.length > 25
                  ? elan.title.slice(0, 25) + '...'
                  : elan.title
              }
              description={
                elan.description.length > 30
                  ? elan.description.slice(0, 30) + '...'
                  : elan.description
              }
              onClick={() => handleElanClick(elan.id)}
            />
          ))

        )}
      </div>
    </div>
  );
};

export default Elanlar;
