import React, { useState, useEffect } from 'react';
import './MotoTaksiSifarish.css';
import { sendMessage, connectWebSocket } from '../../api/sifarish/SifarishController';
import UserController from '../../api/user/UserController';
import { useNavigate } from 'react-router-dom'; // useNavigate import edin
import Loader from '../../components/Loader';
import axios from 'axios';

const MotoTaksiSifarish = () => {
  const [fromTo, setFromTo] = useState('');
  const [weight, setWeight] = useState('');
  const [socketReady, setSocketReady] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const ONE_SIGNAL_APP_ID = "4f18157e-30b6-4b09-a479-0f77db4d67d9";  // OneSignal App ID
  const ONE_SIGNAL_REST_API_KEY = "os_v2_app_j4mbk7rqwzfqtjdzb535wtlh3ezi53eh7iduoofq7gojyvquprj6lrtps32cxy4v577k3f6uvpqypd7dqd2crsivvzccmb3zhh356nq";  // OneSignal REST API Key

  useEffect(() => {
    // WebSocket bağlantısı qurulur və hazır olduqda state yenilənir
    connectWebSocket(() => { }, () => {
      setSocketReady(true);
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!socketReady) {
      alert("WebSocket bağlantısı qurulmayıb. Biraz sonra yenidən cəhd edin.");
      return;
    }

    const userId = localStorage.getItem('motoTaksiUserId');

    if (!userId) {
      alert("Zəhmət olmasa əvvəlcə giriş edin.");
      return;
    }

    try {
      setLoading(true);  // Loading-i true edirik

      const user = await UserController.getUserById(userId);
      const username = user.name;

      const now = new Date();
      const timestamp = now.toLocaleString('az-AZ', {
        hour12: false,
        timeZone: 'Asia/Baku'
      }).replace(',', '');

      const message = `${fromTo}, ${weight} kq, Moto Taksi lazımdır. Qiymət razılaşma yolu ilə`;

      const chatMessage = {
        userId: parseInt(userId),
        username,
        isSeenIds: [],
        userType: 'customer',
        message,
        isWebsite: true,
        timestamp,
        isCompleted: false,
      };

      sendMessage(chatMessage);
      setFromTo('');
      setWeight('');

      await sendPushNotificationToCouriers(
        "🛵 Yeni Moto Taksi Sifarişi!",
        `${username}: ${message}`
      ); 

      alert("Sifarişiniz kuryerlərə göndərildi!");

      // 2 saniyə sonra loadingi false edirik
      setTimeout(() => {
        setLoading(false);  // Loading-i false edirik
      }, 2000);  // 2 saniyəlik gözləmə

      navigate('/profile/sifarishlerim');
    } catch (error) {
      console.error("İstifadəçi məlumatı alınarkən xəta:", error);
      alert("Sifarişi göndərmək mümkün olmadı.");
      setLoading(false);  // Error zamanı da loading-i false edirik
    }
  };

  const sendPushNotificationToCouriers = async (messageTitle, messageBody) => {
    try {
      // API-dən bütün istifadəçiləri alın
      const response = await axios.get('https://mototaksi.az:9898/api/v5/user');

      // Kuryerləri seçirik və yalnız `isDisable` === false olanları
      const couriers = response.data.filter(user =>
        !user.userType?.includes("customer") &&
        !user.isMutedNotifications &&
        user.oneSignal
      );

      const oneSignalIds = couriers.map(user => user.oneSignal);

      if (oneSignalIds.length === 0) {
        console.log("Bildiriş göndəriləcək kuryer tapılmadı.");
        return;
      }

      // Bildirişi göndər
      await axios.post(
        'https://onesignal.com/api/v1/notifications',
        {
          app_id: ONE_SIGNAL_APP_ID,
          include_subscription_ids: oneSignalIds,
          channel_for_external_user_ids: "push",
          headings: { en: messageTitle },
          contents: { en: messageBody },
          android_channel_id: "d3f9c6e1-f70d-4b45-b37d-a969ef323486",
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Basic ${ONE_SIGNAL_REST_API_KEY}`
          }
        }
      );

      console.log("Push bildirişi uğurla göndərildi!");

    } catch (error) {
      console.error("Kuryerlərə push bildirişi göndərərkən xəta:", error.response?.data || error.message);
    }
  };

  return (
    <div className="moto-taksi-container">
      <div className="moto-taksi-form">
        <h2 className="moto-taksi-title">MotoTaksi Sifarişi</h2>
        <form className="sifarish-form" onSubmit={handleSubmit}>

          <input
            className="input-field"
            type="text"
            value={fromTo}
            onChange={(e) => setFromTo(e.target.value)}
            placeholder="Hardan hara"
            required
          />

          <input
            className="input-field"
            type="number"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            placeholder="Kilonuzu daxil edin"
            required
          />

          <div className="display-text">
            <p>Qiymət: Razılaşma yolu ilə</p>
          </div>

          <button type="submit" className="sifarish-btn" disabled={!socketReady}>
            {socketReady ? 'MotoTaksi Sifariş Et' : 'Bağlantı qurulur...'}
          </button>
        </form>
      </div>
      {loading && <Loader />}

    </div>
  );
};

export default MotoTaksiSifarish;
